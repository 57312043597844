<template>
  <b-container>
    <b-row class="mb-2 justify-content-end">
      <b-col  md="8" lg="6">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Introduzca busqueda..." v-model="search">
          <div class="input-group-append">
            <button class="btn btn-success" @click="searchTask()" type="button"><feather-icon icon="SearchIcon" /></button>
            <button class="btn btn-primary" @click="searchReset()" type="button"><feather-icon icon="XIcon"/></button>
          </div>
        </div>
      </b-col>
    </b-row> 
    <b-row>
      <b-col lg="6" v-for="item, index in list" :key="index">
        <b-card no-body class="overflow-hidden visita">
          <b-card-header :class="'bg-' + item.task_category.color">
           <!-- TITULO TARJETA -->
            <b-card-title class="d-flex align-items-center">        
              {{ item.task_category.name + ' - ' + item.description }}
            </b-card-title>
          </b-card-header>
          <a :href="'/edit-task/'+item.id">
            <b-card-body>
              <div class="text-left">
                <span v-if="item.urgency == 1" class="bg-danger estado small text-white text-center">Urgente</span>
                <span class="estado3 small text-center">NUEVA</span>             
            
              </div>
              <!-- En proceso -->
              <div class="mt-2">
                <span class="mr-2">
                  <strong>Referencia servicio: </strong> {{ item.service_reference }}
                </span>
                <br>
                <span class="mr-2">
                 <p> <strong>Fecha y hora de inicio: </strong> {{ item.agreed_start_date ? formatTime(item.agreed_start_date) : (item.date_start ? formatTime(item.date_start) : 'AGENDAR') }} </p><br>
                  <a class="pl-5" :href="'tel:+34' + item.client.phone1">
                    <button class="btn btn-success" type="button" title="Llamar al cliente">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-outgoing"
                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                        <path d="M15 9l5 -5" />
                        <path d="M16 4l4 0l0 4" />
                      </svg>
                    </button>
                  </a>
                    <button class="btn btn-primary ml-2" type="button" title="Reprogramar cita">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-clock"
                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10.5 21h-4.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3" />
                        <path d="M16 3v4" />
                        <path d="M8 3v4" />
                        <path d="M4 11h10" />
                        <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                        <path d="M18 16.5v1.5l.5 .5" />
                      </svg>
                    </button>
                </span>
                <br>
              </div>
            </b-card-body>
          </a>
        </b-card>

      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
export default {
  props: ['type'],
  watch: {
  
  },
  components: { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BCardHeader, BCard, BRow, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay },
  data() {
    return {
      show: false,
      status: [],
      types: [],
      list: [],
      order: [],
      refuse_order: null,
      not_accepted: null,    
      search: ''
    }
  },
  created() {
    // this.show = true;
    this.$http.get("/api/getNewTasks").then((response) => {
    
      this.list = response.data['tasks'];
      
      this.show = false;
    });
  },
  methods: {
    searchTask() {
      this.show = true;
      this.$http.get("/api/getNewTasks?search="+this.search).then((response) => {     
        this.list = response.data['tasks'];      
        this.show = false;
      });
    },
    searchReset() {
      this.search = '';
      this.searchTask();
    },
    formatTime(date) {
      let format_date = new Date(date).toLocaleDateString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit' })
      let format_time = new Date(date).toLocaleTimeString('es-es', { hour: '2-digit', minute: '2-digit' });
      return format_date + ' ( ' + format_time + ' ) '
    },
    formatDate(date) {
      let format_date = new Date(date).toLocaleDateString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit' })
      return format_date
    },
  }, computed: {
    ...mapState({
      // user: state => state.auth.user,   
    }),
  }
}
</script>
<style>
.notification-rounded {
  display: inline-block;
  width: 25px;
  height: 25px;
  color: white;
  padding: 5px;
  padding-top: 3px;
  border-radius: 25px;
  text-align: center;
}

.green {
  background-color: green;
}

.grey {
  background-color: grey;
}

.bg-light-pink {
  background-color: #ffadad !important;
}

.bg-celeste {
  background-color: #9bf6ff !important;
}

.bg-lemon-yellow {
  background-color: #fdffb6 !important;
}

.bg-tea-green {
  background-color: #caffbf !important;
}

.bg-deep-champagne {
  background-color: #ffd6a5 !important;
}

.bg-dark-green {
  background-color: #52b69a !important;
}

.bg-dark-green .card-title {
  color: white !important;
}
.estado3{
  background-color: rgb(100, 158, 29); 
  margin-left: 6px;
  color: white;
  border: 1px solid transparent;
  padding: 10px; 
}
.estado{
  background-color: rgb(241, 131, 58); 
  margin-left: 6px;
  color: white;
  border: 1px solid transparent;
  padding: 10px;
  width: 30%;
}
</style>